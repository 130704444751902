@import "modules/variables.scss";
@import "modules/mixins.scss";

.lb-confirm-send {
  // width: 1200px;
  line-height: initial;
  padding-bottom: 10px;

  p {
    text-align: left;
    padding: 5px 25px;

    &:first-of-type {
      padding-top: 25px;
    }
  }

  > .lb-confirm-send-header {
    height: 70px;
    line-height: 70px;
    text-align: left;
    width: 100%;
    background-color: $duckColor;
    color: $white;
    padding-left: 26px;
  }

  > .lb-confirm-send-text {
    text-align: left;
    font-size: 15px;
    letter-spacing: 0.12px;
    color: $blueSynchrone;
    height: 86px;
    line-height: 86px;
    padding-left: 26px;

    > .month-bold {
      font-weight: 700;
    }
  }

  > .lb-confirm-send-comment-label {
    font-size: 15px;
    height: 20px;
    line-height: 20px;
    font-weight: 700;
    color: $blueSynchrone;
    text-align: left;
    padding-left: 26px;
    margin-bottom: 11px;
  }

  > .lb-confirm-send-comment {
    background-color: #f4f4f4;
    color: $blueSynchrone;
    outline: none;
    border: none;
    font-family: "Open Sans";
    font-weight: 00;
    width: calc(100% - 52px);
    margin: 0 26px;
    min-height: 80px;
    font-size: 12px;
    resize: none;
  }

  .no-prestation-container {
    display: block;
    text-align: left;
    padding-left: 25px;
    margin-bottom: 25px;

    > .checkbox-label {
      padding-left: 20px;
    }
  }

  .checkbox {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;

    &:checked + .checkmark:after {
      display: block;
    }
  }

  .checkmark {
    height: 20px;
    width: 20px;
    display: inline-block;
    position: relative;
    top: 5px;
    background-color: #eee;

    &:after {
      content: "";
      position: absolute;
      display: none;
      left: 5px;
      width: 6px;
      height: 12px;
      border: solid $menuColor;
      border-width: 0 3px 3px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }
  }

  > .specific-cra-container {
    margin-top: 15px;

    > .certified-container {
      display: block;
      text-align: left;
      padding-left: 85px;

      > .checkbox-label {
        padding-left: 20px;
        font-weight: 600;
        font-size: 15px;
      }
    }

    .slider-container {
      text-align: left;
      padding-bottom: 15px;

      .slider-right {
        &:after {
          background-color: $menuColor !important;
        }
      }

      .slider-label {
        font-size: 14px;
        font-weight: 600;
        cursor: pointer;

        em {
          font-weight: normal;
          font-style: initial;
        }
      }
    }

    > .files-container {
      @include pie-clearfix();
      padding-left: 85px;
      padding-bottom: 15px;
      outline: none;

      > .dropped-file {
        float: left;
        width: 160px;
        margin-right: 25px;
        outline: none;

        > .file-icon {
          font-size: 30px;
          margin-right: 25px;
          margin-top: 5px;
          position: relative;
          float: left;
          top: 5px;
        }

        > .filename {
          font-size: 12px;
          float: left;
          font-weight: 600;
          line-height: 38px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          width: 110px;
          text-align: left;
        }

        > .remove-file {
          position: relative;
          top: -13px;
        }
      }

      > .dragzone {
        float: left;
        border: 2px dotted grey;
        width: 200px;
        height: 48px;
        font-size: 14px;
        line-height: 48px;
        cursor: pointer;
      }
    }
  }

}

.lb-confirm-send-actions {
  width: 503px;
  margin: 20px auto 20px auto;
  font-size: 13px;

  > .lb-confirm-send-confirm {
    color: $white;
    display: inline-block;
    background-color: $notifierSuccessColor;
    width: 240px;
    height: 40px;
    line-height: 40px;
    border-radius: 50px;
    cursor: pointer;
    border: none;
    text-align: center;
  }

  > .lb-confirm-send-cancel {
    margin-left: -11px;
    margin-right: 12px;
    display: inline-block;
    color: $white;
    width: 240px;
    height: 40px;
    line-height: 40px;
    background-color: $inactiveSendMonth;
    border-radius: 50px;
    cursor: pointer;
    text-align: center;
  }
}

.testify-send-cra {
  text-align: left;
  padding-left: 26px;
  line-height: 20px;
  margin-bottom: 30px;
  font-size: 15px;
}

.extra-activities-payment-choice-container {
  text-align: left;
  margin: 0 26px 17px 26px;
  padding: 10px;
  border: 1px solid $hoursNotWorked;
  font-size: 15px;

  > .extra-activities-payment-choice-title {
    color: $hoursNotWorked;
    font-weight: 700;
    margin-bottom: 10px;
  }

  .extra-activities-payment-week {
    margin-bottom: 3px;

    > .payment-week-label {
      width: 123px;
      display: inline-block;
    }
  }

  .extra-activities-payment-total {
    margin: 15px 0;
  }

  .duration-total {
    background-color: $hoursNotWorked;
    color: black;
    font-weight: 700;
    padding: 5px;
    display: inline-block;
    width: 80px;
    text-align: center;
  }

  .extra-activity-payment-explanation {
    background-color: $unavailableDay;
    width: 750px;
    padding: 8px;
    margin: 10px 0;
    font-size: 14px;
  }


  .input-type-radio {
    display: inline-block;
    margin-left: 12px;

    > label {
      margin-left: 25px;
      font-size: 12px;
      color: $blueSynchrone;
      font-weight: 600;
    }

    > .radio-button {
      opacity: 0;
      position: relative;
      top: 25px;
      left: -3px;
      z-index: 9999;
    }

    > .fake-radio::before {
      content: "";
      display: inline-block;

      height: 13px;
      width: 13px;

      border: 1px solid;
      border-radius: 50%;
    }

    > .fake-radio::after {
      content: "";
      display: inline-block;
      height: 9px;
      width: 9px;
      border-radius: 50%;
      background-color: $menuColor;
    }

    > .fake-radio {
      position: relative;
      top: 2px;
    }

    > .fake-radio::before,
    > .fake-radio::after {
      position: absolute;
    }

    > .fake-radio::before {
      top: 3px;
    }

    > .fake-radio::after {
      left: 3px;
      top: 6px;
    }

    > .radio-button + .fake-radio::after {
      content: none;
    }

    > .radio-button:checked + .fake-radio::after {
      content: "";
    }
  }
}

@import "modules/variables.scss";
@import "modules/mixins.scss";

#video-cyber-popup-close {
    background-image: url("/img/euro2020/close.png");
    position: absolute;
    top: -7%;
    left: 96%;
    width: 70px;
    height: 70px;
    background-repeat: no-repeat;
    cursor: pointer;
    font-size: 28px;
}

#video-cyber-popup {
    position: relative;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    width: 730px;
    height: 484px;
    background-color: white;
    border-radius: 25px;
    padding-top: 41px;

    #video-popup-subtitle {
        color: $blueSynchrone;
        line-height: 82px;
        font-size: 24px;
        font-weight: 700;
    }
}

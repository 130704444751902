@import "modules/variables.scss";

#contact-menu {
    .contact-menu-title {
        padding: 20px 0;
        font-size: 32px;
        display: inline-block;
        width: calc(100% - 615px);
    }

    .contact-menu-navigation {
        display: inline-block;
        text-align: right;

        .contact-menu-item {
            font-size: 18px;
            border: 3px solid $vacationColor;
            border-radius: 100px;
            width: 300px;
            display: inline-block;
            text-align: center;
            color: $blueSynchrone;
            height: 49px;
            line-height: 43px;
            font-weight: 700;

            &.active {
                background-color: $vacationColor;
                color: white;
            }

            &:not(:last-of-type) {
                margin-right: 15px;
            }
        }
    }
}

.contact-group {
    width: 400px;
    height: 100px;
    line-height: 100px;
    background: #fff;
    padding: 0 10px;
    margin-bottom: 15px;
    margin-right: 75px;
    cursor: pointer;
}

.contact-images {
    display: inline-block;
    img {
        width: 84px;
        height: 84px;
        border-radius: 50px;
        vertical-align: middle;
        margin-right: 10px;
        border: 4px solid #fff;

        + img {
            margin-left: -40px;
        }
    }
}

.contact-identities {
    vertical-align: top;
    display: inline-flex;
    flex-direction: column;
    font-size: 13px;
    height: 100px;
    line-height: 1.6;
    justify-content: center;
}

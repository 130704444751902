@import "modules/variables.scss";
@import "modules/mixins.scss";

.container-event-article {
    position: relative;
    height: 100%;
    border-radius: 4px;
    border: 1px solid #a6b9c5;
    font-weight: normal;
    margin: 10px;
    text-align: left;
    font-size: initial;

    .event-article-front {
        padding: 15px 15px;

        i {
            font-size: initial;
            margin: initial;
            display: initial;
        }

        .event-article-header,
        .event-article-title,
        .event-article-date {
            color: $blueSynchrone;
        }

        img {
            width: 100%;
        }

        .event-article-date {
            padding-bottom: 5px;
        }
    }
}

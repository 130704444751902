@import "modules/variables.scss";
@import "modules/mixins.scss";

.team-planning-header {
    max-width: 1200px;
    font-size: 18px;
    text-align: center;
    background-color: $menuColor;
    color: #fff;
    line-height: 40px;
    font-family: "Montserrat";

    .left-header {
        color: #fff;
        float: left;
        margin-top: 7px;
        margin-left: 20px;
        font-size: 28px;
    }

    .right-header {
        color: #fff;
        float: right;
        margin-top: 7px;
        margin-right: 20px;
        font-size: 28px;
    }

    .month {
        font-weight: 700;
        text-transform: uppercase;
    }

    .year {
        font-weight: 400;
    }
}

@import "modules/variables.scss";
@import "modules/mixins.scss";

#change-personal-email-container {
    width: 40%;
    padding: 35px 50px;
    margin: 0 auto 30px auto;
    background-color: white;
    color: $blueSynchrone;

    .form-change-personal-email-title {
        font-size: 22px;
        margin-bottom: 25px;
    }

    .form-change-personal-email-helper-text {
        font-size: 13px;
        font-weight: 600;
        margin-bottom: 35px;
    }

    .disabled-account-btn {
        opacity: 0.6;
        cursor: not-allowed !important;
    }
}

@import "modules/variables.scss";
@import "modules/mixins.scss";

#video-gta-popup-close {
    background-image: url("/img/gta/close.png");
    position: absolute;
    top: -5%;
    left: 96%;
    width: 70px;
    height: 70px;
    background-repeat: no-repeat;
    cursor: pointer;
    font-size: 28px;
}

#video-gta-popup {
    position: relative;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    width: 722px;
    height: 407px;
    background-color: white;
    border-radius: 25px;
}
@import "modules/variables.scss";
@import "modules/mixins.scss";

.download-cra {
    cursor: pointer;
    display: inline-block;
    font-family: "Open Sans";
    font-size: 13px;
    font-weight: 600;
}

.modality-container {
    font-size: 14px;
    font-weight: 400;
    background-color: #fff;
    height: 20px;
    line-height: 20px;
    text-align: right;
    padding: 0 10px;

    .modality-label {
        font-weight: 700;
    }
}
@import "modules/variables.scss";
@import "modules/mixins.scss";

.container-marketing-article {
    height: 100%;
    display: block;
    border-radius: 4px;
    background-color: $white;
    border: 1px solid #dae2e7;
    text-align: left;
    font-size: initial;
    margin: 10px;

    i {
        font-size: initial;
        margin: initial;
        display: initial;
    }

    .event-article-header {
        padding: 15px 15px;
        color: $blueSynchrone;
    }

    .event-article-title {
        padding: 10px 15px;
        // margin: 0 0 15px 0;
        color: $blueSynchrone;
    }

    img {
        width: 100%;
        margin-bottom: -5px;
    }
}

@import "modules/variables.scss";
@import "modules/mixins.scss";

.container-twitter {
    position: relative;
    border-radius: 4px;
    background-color: #ffffff;
    border: 1px solid #a6b9c5;
    height: 100%;
    margin: 10px;
    font-family: -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI",
        Roboto, "Helvetica Neue", "Fira Sans", Ubuntu, Oxygen, "Oxygen Sans",
        Cantarell, "Droid Sans", "Apple Color Emoji", "Segoe UI Emoji",
        "Segoe UI Emoji", "Segoe UI Symbol", "Lucida Grande", Helvetica, Arial,
        sans-serif;
    line-height: 18px;
    text-align: left;
    font-weight: normal;
    font-size: 14px;

    .content-center {
        padding: 0 10px 10px 10px;
    }

    .link-twitter {
        @include pie-clearfix();
        height: 56px;
        padding: 10px 0;

        .synchrone-img {
            float: left;
            height: 36px;
            width: 36px;
            border-radius: 50%;
            border: 1px solid transparent;
        }

        .link-twitter-synchrone {
            float: left;

            span {
                display: block;
                padding-left: 10px;
            }

            .span-synchrone {
                color: $blueSynchrone;
                font-weight: 600;
                a {
                    text-decoration: none;
                    color: $blueSynchrone;
                }
            }

            .span-synchrone-fr {
                color: #697882;
                font-size: 12px;
            }
        }

        .twitter-logo {
            float: right;
            height: 17px;
            width: 17px;
            img {
                height: 100%;
                width: 100%;
            }
        }
    }

    .img-twitter {
        width: 100%;

        a {
            img {
                width: 100%;
            }
        }
    }

    .title-twitter {
        padding-top: 10px;
    }

    .content-twitter {
        padding-bottom: 20px;
        font-size: 14px;

        p {
            margin: unset;
        }
        a {
            text-decoration: none;
            color: $bluetwitter;
        }
    }
}

@import "modules/variables.scss";
@import "modules/mixins.scss";

.container-linkedin {
    @include pie-clearfix();
    position: relative;
    border-radius: 4px;
    background-color: #ffffff;
    height: 100%;
    font-family: -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI",
        Roboto, "Helvetica Neue", "Fira Sans", Ubuntu, Oxygen, "Oxygen Sans",
        Cantarell, "Droid Sans", "Apple Color Emoji", "Segoe UI Emoji",
        "Segoe UI Emoji", "Segoe UI Symbol", "Lucida Grande", Helvetica, Arial,
        sans-serif;
    font-size: 14px;
    text-align: left;
    font-weight: normal;
    border-top: 1px solid #a6b9c5;
    border-left: 1px solid #a6b9c5;
    border-right: 1px solid #a6b9c5;
    border-bottom: none;

    .content-center {
        padding: 15px 15px 0 15px;
        width: 350px;
    }

    .link-linkedin {
        .synchrone-img {
            float: left;
            height: 48px;
            width: 48px;
            border-radius: 50%;
            border: 1px solid transparent;
        }
    }

    .link-linkedin-synchrone {
        float: left;
        padding-top: 5px;

        span {
            display: block;
            padding-left: 10px;
        }

        .span-synchrone {
            a {
                text-decoration: none;
                color: rgba(#000, 0.9);
                font-weight: 600;
                font-size: 14px;
            }
        }

        .span-synchrone-fr {
            color: #697882;
            font-size: 12px;
        }
    }

    .linkedin-logo {
        float: right;
        height: 17px;
        width: 17px;
        img {
            height: 100%;
            width: 100%;
        }
    }

    .content-linkedin {
        padding-top: 50px;
        font-size: 14px;
        color: rgba(#000, 0.75);

        p {
            margin: 5px 0;
        }

        a {
            text-decoration: none;
            color: $bluetwitter;
        }
    }

    .img-linkedin {
        bottom: -2px;
        position: absolute;
        width: 350px;

        a {
            img {
                width: 100%;
            }
        }
    }
}

@import "modules/variables.scss";
@import "modules/mixins.scss";

#change-password-container {
    width: 40%;
    padding: 35px 50px;
    margin: 0 auto 30px auto;
    background-color: white;
    color: $blueSynchrone;

    .disabled-account-btn {
        opacity: 0.6;
        cursor: not-allowed !important;
    }

    .form-change-password-title {
        font-size: 22px;
        margin-bottom: 25px;
    }

    #change-password-hidden-indicator {
        position: relative;
        right: 20px;
    }

    .password-valid {
        color: $notifierSuccessColor;
        position: absolute;
        right: calc(50% - 150px);
        font-size: 18px;
    }

    .validation-instructions {
        z-index: 99999;
        position: absolute;
        background-color: $autofilled;
        font-size: 14px;
        left: 56%;
        padding: 10px 25px 20px 25px;
        color: $blueSynchrone;

        .validation-instruction-header {
            text-align: left;
            font-size: 14px;
            font-weight: 600;
            margin: 15px 0;
        }

        .validation-instruction {
            text-align: left;
            line-height: 22px;
        }

        .validation-icon {
            color: $eventCodeDescription;
            font-size: 18px;
        }

        .validated {
            > .validation-icon {
                color: $notifierSuccessColor;
            }
        }
    }
}

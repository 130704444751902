@mixin onMobileXs() {
    @media (max-width: $mobileXs) {
        @content;
    }
}

@mixin onMobile() {
    @media (max-width: ($mobile - 1px)) {
        @content;
    }
}

@mixin onSmallMobile() {
    @media (max-width: 400px) {
        @content;
    }
}

@mixin onTablet() {
    @media (max-width: ($tablet - 1px)) {
        @content;
    }
}

@mixin onDesktopLittle() {
    @media (max-width: ($desktop - 1px)) {
        @content;
    }
}

@mixin onQHD() {
    @media (min-width: 2000px) {
        @content;
    }
}

@mixin onTabletOnly() {
    @media (min-width: $mobile) and (max-width: ($tablet - 1px)) {
        @content;
    }
}

@mixin onDesktopOnly() {
    @media (min-width: $tablet) and (max-width: ($desktop - 1px)) {
        @content;
    }
}

@mixin onDesktop() {
    @media (min-width: $tablet) {
        @content;
    }
}

@mixin diagonal($position, $height, $offset, $deg, $color) {
    position: relative;
    overflow: hidden;

    &:after {
        content: "";
        position: absolute;
        left: 50%;
        width: 101%;
        height: $height;
        background-color: $color;
        //border: 1px solid $color;
        @include transform(skewY($deg) translateX(-50%));
        @include onQHD {
            @include transform(skewY($deg * 2 / 3) translateX(-50%));
        }

        @if $position == "top" {
            top: $offset;
        } @else {
            bottom: $offset;
        }
    }
}

@mixin iosCursor() {
    @include onTablet {
        cursor: pointer;
    }
}

@mixin aspect-ratio($width, $height) {
    position: relative;
    &:before {
        display: block;
        content: " ";
        width: 100%;
        padding-top: ($height / $width) * 100%;
    }

    > .content {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }
}

@mixin font-awesome($content) {
    font-family: "Font Awesome 5 Pro";
    font-weight: normal;
    font-style: normal;
    display: inline-block;
    text-decoration: inherit;
    content: $content;
}

@mixin aColor($color) {
    color: $color;
    &:hover,
    &:visited,
    &:focus {
        color: $color;
    }
}

@mixin event-description() {
    color: $white;
    font-size: 12px;
    font-weight: 600;
}

@mixin event-type-button($color) {
    background-color: $color;
    color: $white;
    border-radius: 50px;
    line-height: 43px;
    height: 43px;
    width: 235px;
    max-width: 400px;
    cursor: pointer;
    font-family: $mainFont;
    text-align: center;
}

@mixin event-code-button() {
    background-color: #f4f4f4;
    padding-left: 20px;
    text-align: left;
    color: #17313a;
    border-radius: 50px;
    line-height: 43px;
    height: 43px;
    min-width: 235px;
    cursor: pointer;
    font-family: "Montserrat";
    font-size: 14px;
    max-width: 400px;
    display: inline-flex;
}

@mixin pie-clearfix {
    &:after {
        content: "";
        display: table;
        clear: both;
    }
}

@import "modules/variables.scss";
@import "modules/mixins.scss";

.section-container {
    width: 572px;
    margin-bottom: 50px;
    break-inside: avoid;
    page-break-inside: avoid;
    -webkit-column-break-inside: avoid;

    .section-title {
        background-color: $notifierSuccessColor;
        color: white;
        font-size: 24px;
        font-weight: 700;
        height: 62px;
        line-height: 62px;
        padding-left: 24px;
        border-radius: 8px 8px 0 0;
        position: relative;

        .section-title-label {
            display: inline-block;
            width: calc(100% - 65px);
        }

        .section-title-image {
            display: inline-block;
            position: absolute;
        }
    }
}

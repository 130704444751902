@import "modules/variables.scss";
@import "modules/mixins.scss";

.container {
  .run-as-user {
    background-color: $white;
    display: inline-block !important;
    margin: 5px;
    height: 42px;
    padding: 0 20px;
    color: $blueSynchrone;
    display: block;
    line-height: 42px;
    cursor: pointer;

    &:hover {
      color: $white;
      background-color: $menuColor;
    }
  }

  .inactive-user {
    opacity: 0.4;
  }

  .consultant-user {
    border-left: 4px solid $career;
    &:hover {
      color: $white;
      background-color: $career;
    }
  }

  .internal-user {
    border-left: 4px solid $corail;
    &:hover {
      color: $white;
      background-color: $corail;
    }
  }

  .contractor-user {
    border-left: 4px solid $blueLightSynchrone;
    &:hover {
      color: $white;
      background-color: $blueLightSynchrone;
    }
  }

  .caption-container {
    display: flex;
    align-items: flex-end;
    margin-bottom: 20px;

    .caption {
      margin-right: 20px;
      display: inline-block;

      .caption-color {
        vertical-align: top;
        display: inline-block;
        height: 20px;
        width: 8px;
        margin-right: 5px;
      }

      .caption-title {
        display: inline-block;
      }
    }
  }

  .run-as-favorites {
    background-color: #ffffff88;
    padding: 15px;
    margin-bottom: 15px;
    display: flex;
    align-items: center;

    .run-as-header {
      font-size: 24px;
      color: gold;
      margin-right: 15px;
    }

    .run-as-user {
      box-shadow: 0 3px 10px #f3f3f3;
    }
  }
}

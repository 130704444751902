@import "modules/variables.scss";
@import "modules/mixins.scss";

.satisfaction-max {
  color: $notifierSuccessColor;
  opacity: 0.3;
}

.satisfaction-mid {
  color: $duckColor;
  opacity: 0.3;
}

.satisfaction-min {
  color: $notifierErrorColor;
  opacity: 0.3;
}

.satisfaction-unknown {
  color: $eventCodeDescription;
  opacity: 0.3;
}

.satisfaction-choices-container {
  display: flex;
  justify-content: space-between;
  margin: 0 370px 65px 370px;

  .satisfaction-choice {
    font-size: 72px;
    cursor: pointer;

    &:hover {
      opacity: 1;
    }
  }

  .selected-satisfaction-choice {
    opacity: 1 !important;
  }
}

.send-month-reminder-harass {
  background-color: #E9E8E9;
  text-align: left;
  padding: 20px;

  .phone-number {
    background-color: $blueSynchrone;
    color: white;
    border-radius: 11px;
    font-weight: 700;
    width: 120px;
    display: inline-block;
    text-align: center;
  }
}

.upper-satisfaction-container {
  margin: 40px 0;

  .confirm-satisfaction {
    margin-bottom: 20px;
  }

  .italic-label {
    font-style: italic;
  }
}
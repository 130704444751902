@import "modules/variables.scss";
@import "modules/mixins.scss";

#united-heroes-pop-up-container {
    font-family: "Open Sans";
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    top: 0;
    z-index: 1000000000;
    line-height: normal;

    #united-heroes-pop-up {
        background-image: url("/img/united-heroes/background.png");
        top: 50%;
        left: 50%;
        width: 729px;
        height: 734px;
        background-repeat: no-repeat;
        background-size: contain;
        overflow: hidden;
        position: relative;
        transform: translate(-50%, -50%);
        border-radius: 25px;
        border: 5px solid white;

        @include onDesktopLittle() {
            width: 481px;
            height: 484px;
        }

        #united-heroes-title {
            color: white;
            font-size: 36px;
            margin: 74px 0 79px 0;

            @include onDesktopLittle() {
                font-size: 24px;
                margin: 49px 0 52px 0;
            }
        }

        #united-heroes-logo {
            background-image: url("/img/united-heroes/logo.png");
            width: 390px;
            height: 199px;
            margin: 0 auto;
            background-repeat: no-repeat;
            background-size: contain;

            @include onDesktopLittle() {
                width: 257px;
                height: 131px;
            }
        }

        #united-heroes-description {
            color: white;
            margin: 28px 0 53px 0;
            font-size: 18px;
            font-weight: 700;

            @include onDesktopLittle() {
                font-size: 12px;
                margin: 18px 0 35px 0;
            }
        }

        #united-heroes-pictos {
            margin: 0 auto 31px auto;
            background-image: url("/img/united-heroes/pictos.png");
            background-repeat: no-repeat;
            background-size: contain;
            width: 588px;
            height: 67px;

            @include onDesktopLittle() {
                width: 388px;
                height: 44px;
                margin: 0 auto 20px auto;
            }
        }

        #united-heroes-btn {
            width: 455px;
            height: 93px;
            border-radius: 74px;
            background-color: #23b8d5;
            line-height: 93px;
            color: white;
            font-size: 24px;
            font-weight: 700;
            margin: 0 auto;
            cursor: pointer;
            display: block;

            @include onDesktopLittle() {
                width: 300px;
                height: 61px;
                line-height: 61px;
                font-size: 15.84px;
            }
        }
    }

    #united-heroes-popup-close {
        background-image: url("/img/united-heroes/close.png");
        position: absolute;
        width: 72px;
        height: 70px;
        top: calc(((100vh - 734px) / 2) - 30px);
        right: calc(((100vw - 729px) / 2) - 30px);
        cursor: pointer;
        background-repeat: no-repeat;
        background-size: contain;

        @include onDesktopLittle() {
            top: calc(((100vh - 484px) / 2) - 20px);
            right: calc(((100vw - 481px) / 2) - 20px);
            height: 48px;
            width: 46px;
        }
    }
}

@import "modules/variables.scss";
@import "modules/mixins.scss";

.activity-actions-block {
    // display: flex;
    // justify-content: space-evenly;
    width: 22%;
    padding: 11px;
    line-height: 24px;
    margin-bottom: 18px;

    .cra-display {
        color: $blueSynchrone;
        font-size: 20px;
    }

    .action-btn {
        text-align: center;
        display: inline-block;
        width: 25px;
    }

    i:not(.green-btn):not(.coral-btn) {
        cursor: pointer;
    }

    i {
        font-size: 20px;
    }

    .empty-comment-count-container {
        width: 30px;
    }

    .comment-count-container {
        cursor: pointer;
        font-size: 12px;
        width: 30px;

        .comment-count {
            color: white;
            font-family: "Open Sans";
            position: relative;
            top: -3px;
        }
    }

    .green-btn {
        font-size: 20px;
        color: $notifierSuccessColor;
        width: 25px;
        text-align: center;
    }

    .coral-btn {
        font-size: 20px;
        text-align: center;
        color: $corail;
        width: 25px;
    }

    .download-specific-cra {
        color: $menuColor;
    }
}

@import "modules/variables.scss";
@import "modules/mixins.scss";

.lb-event-delete {
    > .lb-event-delete-message {
        font-size: 15px;
        font-family: $mainFont;
        color: $blueSynchrone;
        line-height: 100px;

        .lb-event-delete-nota-bene {
            font-style: italic;
            line-height: 20px;
            font-size: 13px;
        }

        > .event-code {
            text-transform: uppercase;
            font-weight: 700;
        }

        > .event-code-absence {
            color: $vacationColor;
        }

        > .event-code-activity {
            color: $menuColor;
        }

        > .event-code-hours-not-worked {
            color: $hoursNotWorked;
        }
    }

    > .lb-event-delete-actions {
        display: inline-flex;
        width: 45%;
        justify-content: space-between;
    }
}

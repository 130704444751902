@import "modules/variables.scss";
@import "modules/mixins.scss";

.container-facebook {
    position: relative;
    border-radius: 4px;
    background-color: #ffffff;
    margin: 10px;
    font-family: -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI",
        Roboto, "Helvetica Neue", "Fira Sans", Ubuntu, Oxygen, "Oxygen Sans",
        Cantarell, "Droid Sans", "Apple Color Emoji", "Segoe UI Emoji",
        "Segoe UI Emoji", "Segoe UI Symbol", "Lucida Grande", Helvetica, Arial,
        sans-serif;
    line-height: 18px;
    color: #1d2129;
    font-size: 18px;
    text-align: left;

    .content-center {
        border: 1px solid #a6b9c5;
        padding: 0 10px 10px 10px;
    }

    .link-facebook {
        height: 60px;
        padding: 10px 10px 10px 0;
        position: relative;

        .synchrone-img {
            float: left;
            height: 40px;
            width: 40px;
        }
    }

    .link-facebook-synchrone {
        float: left;
        padding-top: 5px;

        span {
            display: block;
            padding-left: 10px;
        }

        .span-synchrone {
            font-weight: 600;
            color: #1d2129;
            a {
                text-decoration: none;
                color: $blueSynchrone;
            }
        }

        .span-synchrone-fr {
            color: #90949c;
            font-size: 12px;
        }
    }

    .facebook-logo {
        position: absolute;
        font-size: 24px;
        right: 3px;
        top: -30px;

        a {
            color: #365899;

            .fa-facebook-square {
                font-size: 24px !important;
            }
        }

        img {
            height: 100%;
            width: 100%;
        }
    }

    .content-facebook {
        font-size: 14px;
        a {
            text-decoration: none;
            color: #365899;
        }

        p {
            margin: 0;
            font-weight: normal;
        }
    }

    .img-facebook {
        width: 100%;

        a {
            img {
                width: 100%;
                margin-bottom: -2px;
            }
        }
    }
}

@import "modules/variables.scss";
@import "modules/mixins.scss";
@import "modules/common.scss";

.doctor-item {
    background-color: #f0f9f8;
    font-size: 12px;
    width: 98%;
    margin: 0 auto;
    padding: 12px;
    line-height: 35px;

    .bold-title {
        font-weight: 600;
    }

    .doctor-place {
        color: $notifierSuccessColor;
        font-size: 15px;
        font-weight: 600;
        letter-spacing: normal;
        text-transform: uppercase;
        display: inline-block;
        width: 210px;
    }

    .doctor-title {
        width: 65px;
    }

    .doctor-type,
    .doctor-title {
        font-weight: 600;
        display: inline-block;
    }

    .doctor-contact {
        display: flex;
        line-height: normal;
        margin-bottom: 10px;

        .doctor-phones {
            display: flex;
            padding: 5px 0;
            font-weight: 400;
            width: 185px;
            align-items: center;
            border-right: 1px solid $notifierSuccessColor;

            .doctor-phones-content {
                width: 185px;
                margin-left: 8px;
            }

            .fa-phone {
                transform: rotateY(180deg);
                width: 25px;
                font-size: 15px;
            }

            .doctor-phone {
                display: inline-block;
            }
        }

        .doctor-emails {
            display: flex;
            margin-left: 8px;
            font-weight: 400;
            width: 320px;
            align-items: center;

            .doctor-emails-content {
                width: 280px;

                .doctor-email {
                    display: inline-block;
                    color: $blueSynchrone;
                }
            }

            i {
                font-size: 15px;
                width: 25px;
            }
        }
    }

    .doctor-address {
        font-weight: 400;

        i {
            font-size: 20px;
            width: 25px;
        }
    }
}

@import "modules/variables.scss";

#disclaimer {
    color: $white;
    font-weight: 700;
    font-size: 12px;
    font-family: "Open Sans";
    text-align: center;
    width: 1200px;
    padding: 30px;
    //width: 100%;
    //bottom: 15px;
    //position: absolute;
    //padding: 0;
    //z-index: 500;

    > span > a {
        text-decoration: none;
        color: $white;
    }

    &.login-disclaimer, &.reset-disclaimer {
        width: 100%;
        bottom: 15px;
        position: absolute;
        padding: 0;
        z-index: 500;
    }

    &.gdpr-disclaimer {
        width: 100%;
        color: $blueSynchrone !important;
        position: relative !important;


        > span > a {
            color: $blueSynchrone !important;
        }
    }

    &.contractor-disclaimer {
        width: 100%;
        color: $blueSynchrone !important;
        position: absolute;
        left: 0;
        top: calc(100vh - 170px);

        > span > a {
            color: $blueSynchrone !important;
        }
    }
}

@import "modules/variables.scss";
@import "modules/mixins.scss";

.lb-w1200 {
  .lb-extra-activities {
    line-height: initial;
    height: initial;
    text-align: left;
    padding-bottom: 5px;

    > .lb-extra-activities-header {
      height: 70px;
      line-height: 70px;
      width: 100%;
      background-color: $duckColor;
      color: $white;
      text-transform: uppercase;
      padding-left: 26px;
      position: relative;

      .extra-activities-header-title {
        position: absolute;
        font-weight: 700;
      }

      .extra-activities-month-header {
        position: absolute;
        display: inline-block;
        left: calc(50% - 50px);

        .month {
          font-weight: 700;
        }

        .year {
          font-weight: 400;
        }
      }

      .close-lb {
        position: absolute;
        right: 15px;
        top: 15px;
        font-size: 42px;
        cursor: pointer;
      }
    }

    .lb-extra-activities-content {
      margin: 15px 0 15px 30px;

      .warning-message {
        color: $corail;
        font-size: 14px;
      }

      .extra-activities-warning {
        font-size: 15px;
      }

      .extra-activities-message {
        width: 700px;
        display: inline-block;
      }

      .delete-extra-activities-btn {
        display: inline-block;
        border: 1px solid $blueSynchrone;
        border-radius: 150px;
        width: 180px;
        height: 36px;
        font-size: 15px;
        text-align: center;
        font-weight: 400;
        line-height: 36px;
        cursor: pointer;
        position: relative;
        left: 250px;

        i {
          font-size: 18px;
        }
      }
    }

    .extra-activities-form-container {
      margin: 15px 0 15px 30px;

      .extra-activities-form-header-sm {
        margin-left: 110px;
      }

      .extra-activities-form-header-lm {
        margin-left: 185px;
      }

      .extra-activities-form-header {

        .header-container {
          color: white;
          font-size: 14px;
          font-weight: 400;
          font-style: italic;

          div[class^="header-"] {
            display: inline-block;
            height: 40px;
            padding: 5px;
            vertical-align: middle;
          }

          .header-mission {
            background-color: $duckColor;
            width: 135px;
            margin-right: 75px;
            line-height: 15px;
          }

          .header-left {
            width: 190px;
            margin-right: 6px;
            //margin-left: 6px;
            line-height: 15px;
          }

          .header-center {
            width: 125px;
            margin-right: 6px;
            line-height: 15px;
          }

          .header-total {
            width: 50px;
            line-height: 30px;
          }
        }

        .hours-not-worked-header {
          display: inline-block;
          margin-right: 20px;

          .header-left {
            background-color: $newHoursNotWorked;
          }

          .header-center {
            background-color: $newHoursNotWorked;
          }

          .header-total {
            background-color: $newHoursNotWorked;
          }
        }

        .intervention-header {
          display: inline-block;
          margin-left: 55px;

          .no-margin-left {
            margin-left: 0 !important;
          }

          .header-left {
            background-color: $newIntervention;
          }

          .header-center {
            background-color: $newIntervention;
          }

          .header-total {
            background-color: $newIntervention;
          }
        }
      }

      .no-margin-left {
        margin-left: 0 !important;
      }

      .no-complementaries-with-constraints {
        margin-left: 186px !important;
      }

      .extra-activities-form-line {
        display: flex;

        //&:nth-child(even) {background: #CCC}

        > .label {
          width: 110px;
          line-height: 40px;
          text-transform: capitalize;
        }

        //> .overtime-day-line:nth-of-type(3) {
        //  margin-left: 6px;
        //}

        .no-input-extra-activities-mission {
          width: 135px;
          display: inline-block;
          height: 40px;
          line-height: 40px;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .no-input-extra-activities-hour {
          width: 80px;
          text-align: center;
        }

        .no-input-hour-input {
          width: 190px;
        }

        .hour-input-spacer {
          width: 75px;
          height: 40px;
          float: left;
        }

        > .overtime-day-line {
          display: flex;
          //height: 29px;

          .fake-input-overtime-hour {
            width: 190px;
            //margin-left: 6px;
          }

          .fake-input-overtime-overtime {
            width: 125px;
            margin-left: 6px;
          }

          .fake-input-overtime-label {
            width: 75px;
            padding-top: 10px;
            padding-left: 10px;
          }

          .fake-input-overtime-mission {
            width: 135px;
            display: inline-block;
            height: 40px;
            line-height: 40px;
            overflow: hidden;
            text-overflow: ellipsis;
            float: left;
            word-break: break-all;
          }

          .total-hours-container {
            display: flex;
            margin-left: 6px;
            width: 50px;
            background: #EF753F33;
            align-items: center;

            > div {
              border-left: 2px solid $hoursNotWorked;
              width: 48px;
              text-align: center;
            }
          }

          .constraint-total-hours-container {
            background-color: rgba(84, 177, 181, 0.3) !important;

            > div {
              height: 20px;
              border-left: 2px solid $newIntervention;
            }
          }

          .extra-activities-mission {
            width: 135px;
            height: 40px;
            border-top: none;
            border-right: none;
            border-left: 1px solid #979797;
            border-left: none;
          }

          .hour-line-container {
            float: left;
            //border-right: 2px solid $hoursNotWorked;
            padding: 0;

            .extra-activities-code {
              width: 125px;
              order: 3;
              height: 40px;
              margin-left: 6px;
              border-top: none;
              border-right: none;
              border-left: 1px solid #979797;
              border-left: none;
            }

            .no-input-extra-activities-code {
              width: 125px;
              order: 3;
              height: 40px;
              margin-left: 6px;
              border-top: none;
              border-right: none;
              border-left: 1px solid #979797;
              border-left: none;
              line-height: 40px;
              text-overflow: ellipsis;
              overflow: hidden;
            }

            .hour-line {
              display: flex;
              height: 40px;
              align-items: center;

              &:hover {
                background-color: #f4f4f4;

                input[type="time"] {
                  background-color: #f4f4f4;
                }

                > .extra-activities-code {
                  background-color: #f4f4f4;
                  height: 40px;
                }

                > .hour-input-actions {
                  opacity: 1;
                  align-items: center;
                }
              }

              > .hour-input {
                display: inline-flex;
                order: 2;
                height: 40px;
                align-items: center;
                //background-color: red;
                //margin-right: 6px;

                > input[type=time] {
                  height: 40px;
                }

                > .hour-separator {
                  width: 30px;
                  display: inline-block;
                  text-align: center;
                }
              }

              > .fake-hour-input {
                display: inline-flex;
                order: 2;
                align-items: center;
                //background-color: red;
                margin-right: 6px;
                width: 390px;
              }
            }

            .hour-input-actions {
              display: inline-flex;
              order: 1;
              justify-content: space-evenly;
              width: 75px;
              text-align: center;
              opacity: 0;

              i {
                position: relative;
                top: 3px;
                display: inline-block;
                cursor: pointer;
              }


              .fa-plus-circle {
                font-size: 20px;
              }
            }
          }

          > .label {
            text-transform: capitalize;
            font-weight: 700;
            width: 200px;
            float: left;
          }

          > .ticket-input-container {
            display: inline-block;
            border-left: 2px solid $hoursNotWorked;
            padding-left: 10px;

            /* Chrome, Safari, Edge, Opera */
            > .ticket-input::-webkit-outer-spin-button,
            > .ticket-input::-webkit-inner-spin-button {
              -webkit-appearance: none;
              margin: 0;
            }

            /* Firefox */
            > .ticket-input[type="number"] {
              -moz-appearance: textfield;
            }
          }

          .ticket-input,
          input[type="time"] {
            border: none;
            border-bottom: 1px solid #979797;
            font-family: $mainFont;
            font-size: 15px;
            color: #4a4a4a;
            width: 80px;
            text-align: center;
          }

          input[type="time"]::-webkit-inner-spin-button,
          input[type="time"]::-webkit-outer-spin-button {
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
            margin: 0;
          }

          input[type="time"]::-webkit-clear-button {
            -webkit-appearance: none;
          }
        }
      }

      .extra-activities-comment-label {
        font-size: 15px;
        height: 20px;
        line-height: 20px;
        font-weight: 700;
        color: $blueSynchrone;
        text-align: left;
        margin-bottom: 11px;
      }

      .extra-activities-comment {
        background-color: #f4f4f4;
        color: $blueSynchrone;
        outline: none;
        border: none;
        font-family: "Open Sans";
        font-weight: 00;
        width: calc(100% - 26px);
        min-height: 80px;
        font-size: 12px;
        resize: none;
      }

      .overtime-day-total-line {
        display: flex;
        font-weight: 700;
        font-size: 16px;
        height: 40px;
        margin-top: 10px;


        .overtime-day-total-label-complementaries-small {
          width: 375px;
          padding-top: 10px;
        }

        .overtime-day-total-label-complementaries-large {
          width: 410px;
          padding-top: 10px;
          margin-left: 231px;
          text-align: right;
        }

        .overtime-day-total-label-constraints {
          width: 69px;
          padding-top: 10px;
          padding-left: 10px;
        }

        .overtime-day-total-label-constraints-small {
          text-align: right;
          width: 265px;
        }

        .overtime-day-total-label-constraints-large {
          padding-top: 10px;
          margin-left: 76px;
          text-align: right;
        }

        //.overtime-day-total-hour-complementaries {
        //  width: 265px;
        //  margin-left: 6px;
        //}

        .overtime-day-total-mission-complementaries {
          width: 94px;
          margin-left: 6px;
        }

        .overtime-day-total-overtime-complementaries {
          width: 125px;
          margin-left: 6px;
        }

        .overtime-day-total-hour-constraints {
          width: 190px;
          margin-left: 6px;
        }

        .overtime-day-total-overtime-constraints {
          width: 125px;
          margin-left: 6px;
        }

        .overtime-day-total-complementaries {
          background-color: $newHoursNotWorked;
          color: white;
          width: 50px;
          padding-top: 10px;
          text-align: center;
          margin-left: 6px;
        }

        .overtime-day-total-constraints {
          background-color: $newIntervention;
          color: white;
          width: 50px;
          padding-top: 10px;
          text-align: center;
          margin-left: 6px;
        }
      }
    }
  }
}


.no-hover {
  &:hover {
    background-color: unset;
  }
}
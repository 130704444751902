@import "modules/variables.scss";
@import "modules/mixins.scss";

.validate-activity-lb {
    line-height: initial;
    text-align: left;
    max-width: 1200px;
    padding-bottom: 25px;

    .validate-activity-header {
        padding: 0 5px 0 25px;
        height: 75px;
        background-color: $duckColor;
        color: white;
        width: 100%;

        .validate-activity-title {
            float: left;
            text-transform: uppercase;
            font-weight: 700;
            line-height: 75px;
            // width: 72%;
            font-size: 16px;
            letter-spacing: 0.13px;
        }
    }

    .validate-activity-period {
        text-transform: capitalize;
        padding: 15px 25px;
        font-size: 20px;
        font-weight: 700;
    }

    .validate-form-no-prestation {
        color: $menuColor;
        font-weight: bold;
        padding-left: 25px;
        margin-top: 10px;
    }

    .validate-activity-counts {
        height: 50px;
        padding-left: 25px;

        > .activity-count {
            float: left;
            padding: 10px;
            border-left: 6px solid $menuColor;
            font-weight: 700;
            margin-right: 135px;
        }

        > .absence-count {
            float: left;
            padding: 10px;
            border-left: 6px solid $vacationColor;
            font-weight: 700;
        }

        > .total-count-information {
            font-weight: 700;
            float: right;
            margin-right: 25px;

            .total-count {
                display: inline-block;
                text-align: center;
                width: 155px;
                background-color: $duckColor;
                color: $white;
                padding: 10px;
                font-weight: normal;
            }
        }

        .count {
            font-weight: normal;
        }
    }

    .validate-activity-particularities-container {
        float: right;
        position: relative;
        right: 6%;
        font-size: 14px;

        > .validate-activity-particularity {
            margin-bottom: 5px;

            > .icon {
                display: inline-block;
                margin-right: 10px;
                font-size: 18px;
                width: 18px;
            }

            > .label {
                display: inline-block;
            }
        }
    }

    .validate-activity-comment {
        padding: 25px;

        .validate-activity-comment-label {
            margin-bottom: 10px;
            font-size: 15px;
            font-weight: 700;

            > i {
                font-size: 17px;
                margin-right: 5px;
            }
        }

        .validate-activity-comment-input {
            background-color: #e9e8e9;
            min-height: 80px;
            width: 100%;
            border: none;
            resize: none;
            font-family: $mainFont;
        }
    }

    .validate-activity-user-comment-container {
        padding: 25px;

        .validate-activity-user-comment {
            padding: 0 25px;
            min-width: 250px;
            display: inline-block;
        }

        i {
            color: $duckColor;
        }
    }

    .validate-activity-identity-block {
        float: right;
        padding-top: 7px;
        padding-right: 10px;

        .validate-activity-identity {
            font-size: 15px;
            font-weight: 700;
        }

        .validate-activity-person-type {
            display: inline-block;
            width: 60px;
            color: $blueSynchrone;
            background-color: #e9e8e9;
            border-radius: 9px;
            font-size: 9px;
            text-align: center;
            font-weight: 600;
            letter-spacing: 0.08px;
            line-height: 7.4px;
            margin-right: 5px;
        }

        .validate-activity-matricule {
            display: inline-block;
        }

        .validate-activity-matricule,
        .validate-activity-manager {
            font-weight: 100;
            font-size: 13px;
        }

        .fa-stack {
            font-size: 12px;

            .inside-icon {
                font-size: 14px;
            }
        }
    }

    > .validate-activity-actions {
        margin-top: 20px;
        text-align: center;
        display: flex;
        justify-content: space-evenly;

        > .cancel-btn {
            background-color: $inactiveSendMonth;
        }

        > .refuse-btn {
            @include event-type-button($corail);
        }
    }
}

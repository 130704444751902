@import "modules/variables.scss";
@import "modules/mixins.scss";

.main-documents-container {
    margin-top: 15px;
    margin-bottom: 40px;

    .welcome-book-thumbnail {
        display: inline-block;
        width: 120px;
        margin-right: 10px;
        vertical-align: bottom;
    }

    .main-documents-infos {
        display: inline-block;
        width: calc(100% - 130px);

        .file-row {
            width: 100% !important;
        }
    }
}

@import "modules/variables.scss";
@import "modules/mixins.scss";

.lightbox {
    background-color: #fff;
    width: 50%;
    margin: auto;
}

.lb-centered {
    position: relative;
    transform: translateY(-50%);
    top: 50%;
}

.lb-w1200 {
    margin-top: 100px;
    width: 1200px !important;
    min-height: 280px;

    @include onDesktopLittle {
        overflow: auto;
        max-height: 500px;
    }
}

.lb-mw50 {
    min-width: 50%;
    max-width: 1000px;
}

.confirm-message {
    margin: 20px 0;
}
@import "modules/variables.scss";

.absence-status {
    float: left;
    width: 4%;
    height: 25px;

    > .submitted,
    .validated1,
    .validated2,
    .validated,
    .refused {
        display: inline-block;
        width: 18px;
        height: 18px;
        top: 2px;
        position: relative;
    }

    > .submitted {
        border: 2px solid $vacationColor;
        border-radius: 50%;

        .tooltip {
            line-height: 12px;
            height: 25px;
        }
    }

    > .validated,
    > .validated1,
    > .validated2 {
        border: 2px solid $vacationColor;
        border-radius: 50%;
        background-color: $vacationColor;

        .tooltip {
            line-height: 25px;
            height: 25px;
        }
    }

    > .refused {
        background-image: url("/img/close.svg");
        background-size: 100%;
        top: 4px;

        > .tooltip {
            line-height: 25px;
            height: 25px;
        }
    }

    .tooltip {
        display: none;
        position: absolute;
        width: 56px;
        left: -67px;
        top: -4px;
        font-size: 12px;
        font-weight: 600;
        font-style: italic;
        background-color: #d8d8d8;
        z-index: 99999999;
        text-align: center;
    }

    &:hover .tooltip {
        display: block;
    }
}

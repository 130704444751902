@import "modules/variables.scss";
@import "modules/mixins.scss";

#login-container {
    position: relative;
    width: 100%;
    height: 100%;
    background-image: url("/ecran-connexion.png");
    background-size: cover;
    // background-position: center center;
    z-index: 10;
    
    &::before {
        content: "";
        position: fixed;
        display: block;
        z-index: 10;
        width: 100%;
        height: 100%;
        background-color: rgba(23, 49, 58, 0.5);
    }
    
    .field-container {
        border-bottom: 1px solid $white;
        
        + .field-container {
            margin-top: 50px;
        }
    
    }
    input::-ms-reveal,
          input::-ms-clear {
            display: none;
          }

    input[type="text"],
    input[type="password"] {
        font-weight: normal;
        font-size: 18px;
        background-color: transparent;
        border: none;
        color: $white;
        font-family: $mainFont;
        width: 100%;

        &::-webkit-input-placeholder {
            /* Chrome/Opera/Safari */
            color: rgba(#fff, 0.6);
        }
        &::-moz-placeholder {
            /* Firefox 19+ */
            color: rgba(#fff, 0.6);
        }
        &:-ms-input-placeholder {
            /* IE 10+ */
            color: rgba(#fff, 0.6);
        }
        &:-moz-placeholder {
            /* Firefox 18- */
            color: rgba(#fff, 0.6);
        }
    }

    input[type="submit"],
    button {
        // margin-top: 50px;
        height: 45px;
        line-height: 41px;
        border: 2px solid #fff;
        width: 100%;
        background-color: transparent;
        border-radius: 45px;
        color: #fff;
        padding: 0;
        font-family: $mainFont;
        font-size: 15px;
        font-weight: 600;
        cursor: pointer;
    }

    .login-error {
        position: absolute;
        left: 120px;
        right: 120px;
        top: -100px;
        height: 70px;
        line-height: 70px;
        background-color: #f05a5a;
        color: #fff;
        font-size: 15px;
        font-weight: 700;
    }
}

#form-login-container {
    position: fixed;
    z-index: 50;
    top: 50%;
    left: 0;
    right: 0;
    text-align: center;
    transform: translate(0, -50%);

    img {
        max-width: calc(100% - 20px);
        width: 434px;
        margin: 50px 0;
    }

    form {
        width: 250px;
        margin: 0 auto;
    }

    .forgot-password {
        display: inline-block;
        color: $green;
        margin: 10px 0 25px 0;
        font-weight: 700;
    }
}

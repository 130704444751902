@import "modules/variables.scss";
@import "modules/mixins.scss";

.see-more-overtime {
    position: relative;
    left: 130px;
    font-size: 12px;
    top: 30px;
    width: 20px;
    text-align: center;
    border-radius: 50%;
    background-color: $blueSynchrone;
    cursor: pointer !important;
    color: white;
    height: 19px;
    padding-top: 3px;

    i {
        cursor: pointer !important;
    }

    .more-overtime-icon {
        font-weight: 400;
    }

    &:hover {
        transform: scale(1.1);
    }
}

.higher-see-more-overtime {
    top: 10px !important;
}

.hours-for-day {
    font-style: italic;
    font-weight: 300;
    font-size: 12px;
    position: relative;
    left: 120px;
    top: -19px;
    color: #979797;
    height: 15px;
}

.add-extra-activity {
    position: relative;
    left: 169px;
    top: 20px;
    cursor: pointer;
    width: 15px;

    i {
        font-size: 24px;
        color: $blueSynchrone;
    }
}
@import "modules/variables.scss";
@import "modules/mixins.scss";

.container-press-release {
    border-radius: 4px;
    margin: 10px;
    background-color: #12afa9;
    padding: 10px 25px;
    color: $white !important;
    line-height: 1.4;
    display: block;
    height: 100%;
    max-height: 380px;

    > .press-release-title {
        padding: 20px 0;
    }

    > .press-release-content {
        padding: 20px 0;
    }

    > img {
        width: 100%;
    }
}

@import "modules/variables.scss";

.lds-ripple {
    z-index: 12000;
    top: 50%;
    display: block;
    position: fixed;
    width: 80px;
    height: 80px;
    margin: -40px 0 0 -40px;
    left: 50%;
}

.lds-ripple div {
    position: absolute;
    border: 4px solid $blueSynchrone;
    opacity: 1;
    border-radius: 50%;
    animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}

.lds-ripple div:nth-child(2) {
    animation-delay: -0.5s;
}

.lds-ripple-inside {
    position: relative;
    top: 90px;
}

@keyframes lds-ripple {
    0% {
        top: 36px;
        left: 36px;
        width: 0;
        height: 0;
        opacity: 1;
    }
    100% {
        top: 0px;
        left: 0px;
        width: 72px;
        height: 72px;
        opacity: 0;
    }
}

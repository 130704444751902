.contact-block {
    width: 400px;
    margin-bottom: 15px;

    .contact {
        width: 100%;
        margin-bottom: 5px;
    }
}

.contact-help {
    font-size: 12px;
    letter-spacing: 0.1px;
    font-style: italic;
}

@import "modules/variables.scss";
@import "modules/mixins.scss";
@import "modules/common.scss";

.folder-container {
    background-color: #fff;
    padding: 10px 5px;
    font-size: 15px;
    font-weight: 600;
    // height: 60px;
    line-height: 60px;
    cursor: pointer;
    position: relative;

    &:not(:last-of-type) {
        border-bottom: 1px solid #dadada;
    }

    .folder-label {
        font-weight: 600;
        line-height: 40px;
        margin-left: 7px;
        width: 94%;
        display: inline-block;
    }

    .expand-icon {
        display: inline-block;
        font-size: 24px;
        color: $notifierSuccessColor;
    }

    .expand-icon-before {
        margin-right: 10px;
    }

    .rotated-icon {
        transform: rotate(90deg);
    }

    &.union-folder-container {
        padding: 0 5px;
    }
}

.folder-container.closed:last-of-type {
    border-radius: 0 0 8px 8px;
}

@import "modules/variables.scss";
@import "modules/mixins.scss";

.vacation-count {
    position: fixed;
    display: flex;
    flex-direction: column;
    right: calc((100% - 1199px) / 2);
    width: 410px;
    height: 410px;
    background-color: #fff;
    box-shadow: 1px 1px 4px 1px rgba(122, 121, 121, 0.5);
    border-radius: 25px 25px 0 0;
    transform: translateY(0);
    transition-duration: 250ms;
    top: calc(100% - 62px);

    &.vacation-count-open {
        transform: translateY(-341px);

        .right-vacation {
            display: block !important;
        }
    }
}

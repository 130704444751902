.contact {
    cursor: pointer;
    width: 350px;
    height: 100px;
    line-height: 100px;
    background: #fff;
    padding: 0 10px;
    margin-bottom: 20px;
    margin-right: 75px;
    font-size: 13px;

    &:nth-child(3n) {
        margin-right: 0;
    }
    // float: left;
    // margin: 20px (150px / 6);

    img {
        width: 80px;
        height: 80px;
        border-radius: 40px;
        vertical-align: middle;
        margin-right: 10px;
    }

    &.consultant-contact {
        line-height: 1.6;
        padding: 10px 15px;
        position: relative;

        .contact-birthdate {
            position: absolute;
            right: 10px;
            bottom: 10px;
            font-size: 12px;
            font-weight: 700;
            font-style: italic;

            i {
                font-size: 16px;
                font-weight: 100;
            }
        }
    }
}

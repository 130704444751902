.contact-list {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    flex-direction: row;
}

.contact-col {
    margin-right: 94px;
}

@import "modules/variables.scss";
@import "modules/mixins.scss";

#scorp-popup {
    background-color: $corail;
    position: relative;
    top: 50%;
    transform: translate(0, -50%);
    margin: 0 auto;
    line-height: 17px;
    width: 730px;
    text-align: left;
    font-family: 'proxima-nova';

    #scorp-popup-close {
        background-color: $corail;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        border-radius: 50%;
        width: 72px;
        height: 72px;
        position: relative;
        left: calc(730px - 50px);
        top: -24px;
        cursor: pointer;
        float: left;

        .close-icon {
            color: #fff;
            font-size: 36px;
            position: relative;
            transform: translate(-50%, -50%);
            top: 50%;
            left: 50%;
            z-index: 9;
        }
    }

    .scorp-popup-header {
        height: 343px;

        .scorp-popup-header-text {
            color: #fff;
            background-color: $corail;
            height: 134px;
            position: relative;
            top: -73px;
            text-align: center;
            padding-top: 17px;
            clear: left;

            .scorp-popup-header-title {
                text-transform: uppercase;
                font-size: 22px;
                margin-bottom: 24px;
                padding: 40px 0 30px 0;
                font-weight: 700;
            }

            .scorp-popup-header-content {
                font-size: 22px;
                line-height: 22px;
                font-weight: 200;
                text-align: justify;
                margin: 0 auto;
                width: 80%;
                padding-bottom: 50px;
            }
        }
    }
}
@import "modules/variables.scss";

.send-month-btn {
    color: $white;
    width: 240px;
    height: 37px;
    background-color: $inactiveSendMonth;
    text-align: center;
    font-family: "Open Sans";
    font-size: 14px;
    line-height: 37px;
    letter-spacing: 0.4px;
    border-radius: 50px;
    margin-top: 23px;
    margin-right: 15px;
    display: inline-block;
    cursor: not-allowed;

    &.active {
        cursor: pointer;
        background-color: $duckColor !important;
    }
}

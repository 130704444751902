@import "modules/variables.scss";
@import "modules/mixins.scss";

$columnCounter: 3;
$gutter: 45px;

.home-page {
    position: relative;
    background-size: cover;
    background-position: center center;
    min-width: 1200px;
    padding-top: 0 !important;

    &:not(.home-page-contractor) {
        &:before {
            background-image: linear-gradient(
                    0deg,
                    #17313a calc(100% - 425px),
                    white 425px
            )
        }
    }

    &.home-page-contractor {
        background-image: url("/img/background-contractor.png");
        background-size: cover;

        &:before {
            background: rgba(255,255,255,0.5);
        }
    }

    &:before {
            content: "";
            display: block;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            z-index: 0;
        }

    .homepage-container {
        width: 100%;
        position: relative;

        .instagram-upper-container {
            width: 27% !important;
        }

        .home-upper-container {
            height: 390px;
            justify-content: space-around;
            margin-top: 20px;

            > .home-internal-news {
                display: flex;
                box-shadow: 3px 3px 10px #e0e0e0;
                width: 66%;
                height: 100%;

                > .home-internal-picture {
                    height: 100%;
                }

                > .home-internal-content {
                    padding: 20px 17px;

                    > .home-internal-title {
                        font-weight: 600;
                    }

                    > .home-internal-text {
                        font-size: 13px;
                        margin-top: 10px;

                        > p {
                            margin-bottom: 10px;
                            display: block;

                            > .united-heroes-link, .home-internal-news-link {
                                text-decoration: underline;
                                color: $blueSynchrone;
                                font-weight: 600;
                            }

                            > .bolder {
                                font-weight: 900;
                            }

                        }
                    }


                }
            }
        }

        .home-upper-container,
        .home-lower-container {
            display: flex;
        }

        .home-lower-container {
            justify-content: flex-start;
            margin: 50px auto 0 auto;
            display: flex;
            flex-wrap: wrap;
            width: 640px;
        }

        .lower-container-no-width {
            width: unset;
            margin-top: 150px !important;
        }

        #euro-2020-link {
            background-image: url("/img/euro2020/link.png");
            width: 267px;
            height: 116px;
            position: absolute;
            left: 65%;
            cursor: pointer;
            opacity: 0.8;

            &:hover {
                opacity: 1;
            }
        }

        #open-cyber-popup-link {
            background-image: url("/img/cyber/link.png");
            background-repeat: no-repeat;
            background-size: contain;
            width: 268px;
            height: 118px;
            position: absolute;
            left: 65%;
            cursor: pointer;
            opacity: 0.8;

            &:hover {
                opacity: 1;
            }

            @include onDesktopLittle() {
                width: 201px;
                height: 88.5px;
                left: 72%;
            }
        }

        #united-heroes-popup-link {
            background-image: url("/img/united-heroes/inter-all.png");
            width: 268px;
            height: 118px;
            position: absolute;
            left: 65%;
            cursor: pointer;
            opacity: 0.8;

            &:hover {
                opacity: 1;
            }
        }
    }

    .header-container {
        min-height: 90px;
        padding: 3em 0;

        h1 {
            // line-height: 65px;
            color: #ffffff;
            font-family: $mainFont;
            font-size: 48px;
            font-weight: normal;
            padding: 0;
            margin: 0;

            @include onDesktopLittle() {
                font-size: 30px;
            }
        }
    }
}

.home-app-container {
    width: calc(
        (100% - (#{$columnCounter} - 1) * #{$gutter}) / #{$columnCounter}
    );
    float: left;
    height: 475px;

    // @include onDesktopLittle() {
    //     height: 370px;
    // }

    + .home-app-container {
        margin-left: $gutter;
    }
}

.home-apps {
    @include pie-clearfix();
    display: flex;
    flex-direction: column;

    .home-news-container {
        width: 350px;
        height: 390px;
        overflow: hidden;
        box-shadow: 3px 3px 10px #e0e0e0;
        position: relative;

        .show-more {
            position: absolute;
            bottom: 0;
            background: linear-gradient(0deg, rgba(255, 255, 255, 1) 50%, rgba(0, 0, 0, 0) 100%);
            width: 100%;
            height: 80px;
            text-align: center;
            font-size: 14px;

            a {
                position: absolute;
                display: block;
                font-weight: 600;
                text-decoration: underline;
                bottom: 10px;
                left: 0;
                width: 100%;
                text-align: center;
                font-size: 13px;
                color: #17313a;
            }
        }

        .j-instagram {
            height: 390px;
            overflow: hidden;
            //display: none;

            &.j-video {
                display: block;
            }

            .j-poster {
                display: none;
            }

            img {
                position: relative;
                z-index: 10;
            }
        }

        a {
            color: $blueSynchrone;
        }

        .home-news-title {
            padding: 10px 26px;
        }

        > .home-news-image-container {
            max-height: 200px;
            overflow: hidden;

            > img {
                position: relative;
                height: 318px;
                transform: translateY(-59px);
            }
        }
    }
}

.home-double-app-container {
    padding-top: 45px;
    height: 150px;
    width: 310px;
    background-color: #fff;
    border-radius: 8px;
    text-align: center;
    font-family: $mainFont;
    font-size: 14px;
    margin-right: 10px;
    display: flex;

    .dashed-box-shadow {
        position: relative;
        height: 120px;
    }

    .dashed-box-shadow:after {
        position: absolute;
        content: "";
        top: -25px;
        left: 0px;
        height: 10px;
        width: 2px;
        background: $blueSynchrone;
        box-shadow: 0px 20px 0px $blueSynchrone, 0px 40px 0px $blueSynchrone,
        0px 60px 0px $blueSynchrone, 0px 80px 0px $blueSynchrone,
        0px 100px 0px $blueSynchrone;
    }

    > .home-double-app {
        width: 50%;
        color: $blueSynchrone;

        > .home-app-subtext {
            font-weight: 100;
            font-size: 12px;
        }

        > .home-app-content {
            font-size: 20px !important;
        }

        > i {
            font-size: 42px;
            margin-bottom: 15px;
        }
    }
}

.home-app {
    color: $blueSynchrone;
    padding-top: 45px;
    height: 150px;
    width: 150px;
    background-color: #fff;
    border-radius: 8px;
    text-align: center;
    font-family: $mainFont;
    font-size: 14px;
    margin-bottom: 10px;

    .home-app-subtext {
        font-weight: 100;
        font-size: 12px;
    }

    .home-app-content {
        font-size: 20px !important;
    }

    i {
        font-size: 42px;
        margin-bottom: 15px;
    }
}

.home-app:not(:last-child) {
    margin-right: 10px;
}

.home-app-btn {
    color: currentColor;
    position: absolute;
    display: block;
    width: 68%;
    border: 1px solid currentColor;
    margin: 0 16%;
    font-size: 15px;
    font-weight: 600;
    bottom: 15px;
    height: 45px;
    line-height: 43px;
    text-align: center;
    border-radius: 30px;
    text-decoration: none;
}

.documents-app {
    font-size: 18px !important;
    padding-top: 25px !important;

    i {
        float: left;
        display: block !important;
        margin: -8px 12px !important;
        font-size: 42px !important;
    }

    .home-app-btn {
        position: relative;
        top: 20%;
        margin-bottom: 30px;
    }
}

.my-activity-app {
    overflow: hidden;
    height: 65% !important;
    position: relative;

    .home-app-content {
        position: relative !important;
        top: 20% !important;
    }

    &:before {
        content: "C.R.A. :";
        text-align: left;
        position: absolute;
        font-style: italic;
        top: -40px;
        left: -25px;
        display: block;
        width: 150px;
        height: 150px;
        font-size: 15px;
        padding-top: 55px;
        padding-left: 50px;
        z-index: 10;
    }

    &:after {
        content: "avant le\a 25 du mois";
        text-align: left;
        position: absolute;
        top: -40px;
        left: -25px;
        display: block;
        width: 100px;
        height: 150px;
        font-size: 12px;
        padding-top: 75px;
        padding-left: 50px;
        // background-image: url("/img/pastille.svg");
        // background-position: top center;
        // background-repeat: no-repeat;
        z-index: 1;
        white-space: pre;
    }

    .pastille {
        position: absolute;
        top: -40px;
        left: -25px;
        width: 150px;
    }

    &.full-activity {
        height: 100% !important;
    }
}

.my-activity-app ~ .home-app {
    height: calc(33.3333% - 52px / 3) !important;
    font-size: 18px !important;
    padding-top: 25px !important;

    i {
        float: left;
        display: block !important;
        margin: -8px 12px !important;
        font-size: 42px !important;
    }

    .home-app-btn {
        position: relative;
        top: 25%;
    }
}

#documents-app-content {
    margin: 0 auto;
    width: 135px;
    display: block;
}

#new-gta-popup-link {
    position: fixed;
    background-image: url("/img/gta/thumbnail.png");
    background-repeat: no-repeat;
    background-size: cover;
    width: 319px;
    height: 204px;
    right: 200px;
    cursor: pointer;
    z-index: 100;
    border: 1px solid #D8D8D8;
    bottom: -204px;
}

.hidden-new-gta-popup-link {
    animation: gta-link-movement-reverse 0.1s forwards;
}

.visible-new-gta-popup-link {
    animation: gta-link-movement 1s forwards;
}

@keyframes gta-link-movement {
    0% {
        bottom: -204px;
    }

    50% {
        bottom: 0;
    }

    100% {
        bottom: -20px;
    }
}

@keyframes gta-link-movement-reverse {
    0% {
        bottom: -20px;
    }

    25% {
        bottom: 0;
    }

    100% {
        bottom: -204px;
    }
}
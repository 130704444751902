@import "modules/variables.scss";
@import "modules/mixins.scss";

#euro-2020-pop-up-container {
    font-family: "Open Sans";
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    top: 0;
    z-index: 1000000000;
    line-height: normal;

    #euro-2020-popup-close {
        background-image: url('/img/euro2020/close.png');
        position: absolute;
        width: 70px;
        height: 70px;
        top: 18%;
        left: 62%;
        cursor: pointer;
        background-repeat: no-repeat;

        @include onDesktopLittle() {
            left: 68%;
        }
    }

    #euro-2020-pop-up {
        background-image: url('/img/euro2020/background.png');
        top: 50%;
        left: 50%;
        width: 619px;
        height: 620px;
        background-repeat: no-repeat;
        overflow: hidden;
        border-radius: 50%;
        position: relative;
        transform: translate(-50%,-50%);

        .euro-2020-header-text {
            color: $adminHeaderColor;
            text-align: center;
            height: 236px;
            
            .first-line-header {
                padding-top: 52px;
                font-size: 18px;
            }

            .second-line-header {
                font-size: 36px;
                font-weight: 900;
            }
        }

        .euro-2020-logo-container {
            width: 100%;
            height: 182px;
            text-align: center;
        }

        .euro-2020-description-container {
            font-size: 20px;
            font-weight: 700;
            width: 100%;
            height: 69px;
            text-align: center;

            .euro-2020-description {
                width: 345px;
                margin: 0 auto;
            }
        }
        
        .eurobet-link {
            display: block;
            background-color: $duckColor;
            width: 100%;
            height: 200px;
            color: white;
            text-align: center;
            font-size: 24px;
            font-weight: 700;
            padding-top: 41px;

            &:hover {
                background-color: $blueSynchrone;
                color: $adminHeaderColor;
            }
        }
    }
}
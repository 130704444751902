@import "modules/variables.scss";

.ask-extra-activity-btn {
    color: $white;
    width: 420px;
    height: 37px;
    text-align: center;
    font-family: "Open Sans";
    font-size: 14px;
    line-height: 37px;
    letter-spacing: 0.4px;
    border-radius: 50px;
    margin-top: 23px;
    margin-right: 15px;
    display: block;
    cursor: pointer;
    background-color: $notifierSuccessColor !important;
    //background-color: $inactiveSendMonth;
    //cursor: not-allowed;

    &.active {
    }
}

@import "modules/variables.scss";
@import "modules/mixins.scss";

.team-planning-container {
    margin: 90px auto 0 auto;
    width: 1200px;

    .cra-list-title-container {
        padding-bottom: 20px;
        display: flex;

        .cra-list-title {
            font-size: 32px;
            height: 70px;
            align-items: flex-end;
            display: flex;
        }

        .caption-container {
            display: flex;
            margin-left: 60px;
            height: 70px;
            align-items: flex-end;

            .caption {
                margin-right: 20px;
                display: inline-block;

                .caption-color {
                    vertical-align: top;
                    display: inline-block;
                    height: 20px;
                    width: 20px;
                    margin-right: 5px;
                }

                .caption-title {
                    display: inline-block;
                }
            }
        }
    }

    table {
        border-collapse: collapse;
        width: 1200px;
        margin-bottom: 10px;

        tr {
            height: 60px;
        }

        td,
        th {
            border-top: 15px solid #f2f2f2;
            background-color: #fff;
            border-bottom: 15px solid #f2f2f2;
            // border-left: 1px solid #ccc;
            border-right: 1px solid #f2f2f2;
        }

        th {
            color: #9b9b9b;
        }

        .open-manager-indicator {
            display: inline-block;
            cursor: pointer;
            position: absolute;
            // top: 50%;
            // transform: translateY(-50%);
            vertical-align: middle;
            font-weight: 900;
        }
    }

    .empty-header {
        min-width: 250px;
        background-color: #f2f2f2;
    }

    .day-header {
        max-width: 25px;
        text-align: center;
        text-transform: capitalize;
        color: #9b9b9b;
        font-weight: normal;
        background-color: #f2f2f2;
        border-right: 1px solid #9b9b9b;
        padding: 0 2px;

        &:last-of-type {
            border-right: unset;
        }
    }

    .absent-cell {
        background-color: $vacationColor;
    }

    .team-planning-footer {
        color: $greyDark;
        font-style: italic;
    }

    .managee-identity-cell {
        position: relative;
        max-width: 390px;
        padding-left: 10px;
        line-height: 30px;
    }

    .managee-identity-container {
        display: inline-block;
        font-size: 15px;
        font-weight: 600;
        width: 90%;
        line-height: 30px;
        white-space: nowrap;

        > .person-type-badge {
            vertical-align: middle;
            white-space: normal;
        }

        .managee-identity-label {
            display: inline-block;
            vertical-align: middle;
            max-width: calc(100% - 65px);
            text-overflow: ellipsis;
            overflow: hidden;
        }
    }
}

@import "modules/variables.scss";
@import "modules/mixins.scss";
@import "modules/common.scss";

.slider-container {
    font-size: 12px;
    margin-left: 30px;
}

.slider {
    position: relative;
    display: inline-block;
    width: 40px;
    height: 19px;
    font-size: 13px;
    line-height: 20px;
    top: 1px;
    cursor: pointer;

    &:before {
        position: absolute;
        z-index: 10000;
        top: 1px;
        right: 6px;
        width: 28px;
        height: 13px;
        margin-top: 6px;
        background-color: #d8d8d8;
        content: "";
        border-radius: 10px;
        transition: 0.2s;
    }

    &:after {
        position: absolute;
        z-index: 10001;
        top: -4px;
        right: 21px;
        width: 18px;
        height: 18px;
        background-color: #9b9b9b;
        margin-top: 8px;
        border-radius: 50%;
        // background: $white;
        content: "";
        transition: 0.2s;
    }
}

.slider-right {
    &:after {
        right: 2px !important;
    }
}

.absence {
    &:after {
        background-color: $vacationColor !important;
    }
}

.activity {
    &:after {
        background-color: $menuColor !important;
    }
}

.hours-not-worked {
    &:after {
        background-color: $hoursNotWorked !important;
    }
}

.left-label {
    display: inline-block;
    width: 50px;
}

.right-label {
    margin-left: 20px;
}

.locked {
    cursor: default !important;

    &:after {
        background-color: #9b9b9b !important;
    }
}

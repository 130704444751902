@import "modules/variables.scss";
@import "modules/mixins.scss";

#overlay {
  position: fixed;
  z-index: 1000;
  width: 0;
  height: 0;
  overflow-y: scroll;
  background-color: rgba(10, 10, 10, 0.5);
  top: 50%;
  left: 50%;
  display: block;
  line-height: 150px;
  text-align: center;
  transition: width 0.2s, height 0.2s, top 0.2s, left 0.2s;

  &.open {
    //display: block;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

@keyframes open-overlay {
  from {
    display: none;
  }

  to {
    display: block;
  }
}
@import "modules/variables.scss";
@import "modules/mixins.scss";

.container-interview {
    border-radius: 4px;
    background-color: $blueLightSynchrone;
    padding: 15px 25px;
    margin: 10px;
    color: #ffffff !important;
    font-size: initial;
    line-height: 1.4;
    display: block;
    height: 100%;
    text-align: left;

    .interview-title {
        margin-bottom: 20px;
    }

    img {
        width: 100%;
    }

    .interview-content {
        font-size: 22px;
        margin: 20px 0 5px 0;
    }

    .interview-summary {
        font-size: 18px;
    }
}

.search-container {
    width: 1200px;
    background-color: #e6d9e0;
    height: 60px;
    margin-bottom: 20px;

    > .fa-search {
        float: left;
        font-size: 40px;
        line-height: 60px;
        margin-left: 15px;
        cursor: pointer;
    }

    > .contact-searchbar {
        width: calc(100% - 110px);
        height: 100%;
        line-height: 60px;
        background-color: transparent;
        border: none;
        font-family: "Open Sans";
        font-style: italic;
        font-size: 14px;
        letter-spacing: 0.12px;
        padding-left: 180px;
        outline: none;
    }

    > .fa-times {
        float: right;
        font-size: 40px;
        line-height: 60px;
        margin-right: 15px;
        cursor: pointer;
    }
}

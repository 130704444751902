@import "modules/variables.scss";
@import "modules/mixins.scss";

.modify-account-action {
    color: $white;
    width: 100px;
    height: 37px;
    background-color: $duckColor !important;
    text-align: center;
    font-family: "Open Sans";
    font-size: 14px;
    line-height: 37px;
    letter-spacing: 0.4px;
    border-radius: 50px;
    margin-top: 23px;
    margin-left: calc((100% - 100px) / 2);
    cursor: pointer;
    border: none;
}

.my-account-title {
    margin-top: 15px;
    margin-bottom: 19px;
    font-family: "Open Sans", sans-serif;
    font-size: 48px;
    font-weight: normal;
    color: $blueSynchrone;
}

.field-container {
    text-align: center;
    margin-bottom: 10px;

    input {
        width: 65%;
        font-size: 18px;
        color: #a0a0a0;
        border: none;
        border-bottom: 2px solid lightgrey;

        &::placeholder {
            color: #d0d0d0;
        }
    }
}

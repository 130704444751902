@import "modules/variables.scss";

.absence-dates {
    float: left;
    width: 37%;
    height: 25px;

    .absence-startAt,
    .absence-endAt {
        display: inline-block;
        text-transform: capitalize;
        width: 46%;
    }

    .absence-endAt {
        width: 47%;
        margin-left: 10px;
    }

    .fa-chevron-right {
        font-size: 18px;
    }

    .absence-start-moment,
    .absence-end-moment {
        font-weight: 700;
        font-style: italic;
    }
}

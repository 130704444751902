@import "modules/variables.scss";
@import "modules/mixins.scss";

.container-rex {
    height: 100%;
    display: block;
    border-radius: 8px;
    background-color: $white;
    padding: 10px 24px;
    text-align: left;
    border: 1px solid #a6b9c5;

    .rex-title {
        line-height: 1.1em;
        font-size: 16px;
        margin-bottom: 10px;
    }

    .rex-image-container {
        width: 100%;
        margin: 0 auto;
    }
}

@import "modules/variables.scss";

.contact-lb {
    position: relative;
    width: 400px;
    // height: 600px;
    line-height: 1.2;
    transform: translateY(-50%);
    top: 50%;

    img {
        width: 144px;
        height: 144px;
        border-radius: 50%;
        display: block;
        margin: 20px auto 45px auto;
    }

    .contact-identity {
        font-size: 13px;
        height: 20px;
        line-height: 20px;
        margin-bottom: 25px;
    }

    .contact-pictures {
        margin: 0 auto;
        display: inline-block;
        img {
            display: inline-block;
            border: 4px solid #fff;
            margin-bottom: 20px;

            + img {
                margin-left: -50px;
            }
        }
    }

    .contact-line {
        text-align: left;
        font-size: 13px;
        font-weight: 700;
        height: 50px;
        line-height: 50px;

        i {
            font-size: 30px;
            width: 105px;
            text-align: center;
            vertical-align: middle;
        }

        a {
            color: $blueSynchrone;
            text-decoration: none;
        }
    }
    .contact-lines {
        padding-bottom: 40px;
    }

    .contact-title {
        color: #a51160;
        font-size: 14px;
        font-weight: 700;
        height: 70px;
        line-height: 70px;
    }

    .contact-place {
        position: relative;
        height: 40px;
        line-height: 40px;
        background-color: #a51160;
        font-size: 13px;
        font-weight: 500;
        color: #fff;
        font-style: italic;

        i {
            position: absolute;
            font-size: 28px;
            left: 42px;
            line-height: 40px;
        }
    }

    .contact-help {
        padding: 0 40px;
        text-align: left;
        margin-bottom: 15px;
    }

    .close-lb {
        background-image: url("/img/close.svg");
        background-size: 100%;
        cursor: pointer;
        position: absolute;
        right: 15px;
        top: 20px;
        width: 30px;
        height: 30px;
    }
}

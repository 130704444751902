@import "modules/variables.scss";
@import "modules/mixins.scss";

.folder-row {
    background-color: #fff;
    font-size: 15px;
    font-weight: 600;
    padding: 0 16px;
    height: 60px;
    line-height: 60px;
    cursor: pointer;
    position: relative;

    .folder-row-label {
        display: inline-block;
        width: 95%;
        overflow-x: hidden;
        text-overflow: ellipsis;
        vertical-align: bottom;
    }

    .expand-icon {
        display: inline-block;
        font-size: 24px;
        color: $notifierSuccessColor;
    }

    .expand-icon-before {
        margin-right: 10px;
    }

    .rotated-icon {
        transform: rotate(90deg);
    }
}
